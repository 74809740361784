<template>
  <v-card class="elevation-0">
    <v-navigation-drawer
        v-model="drawer"
        :mini-variant="mini"
        permanent
    >
      <div
          class="d-flex justify-space-between pb-0 pr-2"
          style="padding: 14px"
      >
        <v-text-field
            v-if="!mini"
            v-model="search"
            :label="$t('system.search')"
            class="mr-2 fixed-size"
            dense
            outlined
        />
        <v-btn
            icon
            text
            @click.stop="mini = !mini"
        >
          <v-icon
              v-if="mini"
              size="18"
          >fas fa-chevron-right
          </v-icon>
          <v-icon
              v-else
              size="18"
          >fas fa-chevron-left
          </v-icon>
        </v-btn>
      </div>

      <div
          :class="{'mt-7': mini}"
          class="d-flex justify-space-between px-2 mb-2"
      >
        <h5
            v-if="!mini"
            class="font-weight-normal"
        >
          {{ $t('system.calendars') }}
        </h5>
        <v-menu
            v-model="createMenu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                text
                style="margin-top: -5px;"
            >
              <v-icon size="18">fas fa-plus</v-icon>
            </v-btn>
          </template>
          <CreateEditCalendar
              @close="createMenu = false"
              @create="addCalendar"
          />
        </v-menu>
      </div>

      <v-list dense>
        <v-list-item
            v-for="item in filterCalendars()"
            :key="item.id"
        >
          <div class="w-100 d-flex justify-space-between">
            <v-checkbox
                v-model="item.selected"
                :class="{'pt-2': mini}"
                :color="item['calendar_calendars.color']"
                class="mt-0"
                @change="selectCalendars()"
            >
              <template
                  v-if="!mini"
                  v-slot:label
              >
              <span
                  :class="defaultCalendar === item['calendar_calendars.id'] ? 'font-weight-bold' : 'font-weight-normal'"
                  class="checkbox-label"
              >
                {{ item['calendar_calendars.name'] }}
              </span>
              </template>
            </v-checkbox>
            <v-menu
                v-if="!mini"
                :close-on-content-click="false"
                offset-y
            >
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    :disabled="loading"
                    :loading="loading"
                    icon
                    style="position: relative; right: -8px"
                    text
                >
                  <v-icon size="16">fas fa-ellipsis-v</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                    link
                    @click="setAsDefault(item['calendar_calendars.id'])"
                >
                  <v-list-item-title>
                    {{ $t('system.set_as_default') }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                    link
                    @click="item.menu = !item.menu"
                >
                  <v-menu
                      v-model="item.menu"
                      :close-on-content-click="false"
                      offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-title
                          v-bind="attrs"
                          v-on="on"
                      >{{ $t('system.edit') }}
                      </v-list-item-title>
                    </template>
                    <CreateEditCalendar
                        :item="item"
                        @close="item.menu = false"
                        @edit="refreshCalendars(); item.menu = false"
                    />
                  </v-menu>
                </v-list-item>
                <v-list-item link>
                  <v-menu
                      offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-title
                          v-bind="attrs"
                          v-on="on"
                      >
                        <span style="color: #ba0000">{{ $t('system.delete') }}</span>
                      </v-list-item-title>
                    </template>
                    <v-card class="px-3 py-2">
                      {{ $t('system.are_you_sure') }}?
                      <v-btn
                          class="ml-2"
                          color="red red-darken-2"
                          dark
                          small
                          @click="deleteEvent(item['calendar_calendars.id'])"
                      >{{ $t('system.yes') }}
                      </v-btn>
                    </v-card>
                  </v-menu>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-list-item>
        <v-list-item v-if="!calendars.length > 0">
          <i>{{ $t('system.no_data') }}</i>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import CreateEditCalendar from '@/components/Calendar/CreateEditCalendar';
import axios from 'axios';

export default {
  name: 'CalendarSidebar',

  data() {
    return {
      search: '',
      loading: false,
      drawer: true,
      mini: false,
      createMenu: false,
      selectedEvents: [],
    };
  },

  components: {
    CreateEditCalendar,
  },

  props: {
    calendars: {
      type: Array,
      default: () => [],
    },
    defaultCalendar: {
      type: Number,
      default: () => null,
    },
  },

  methods: {
    refreshCalendars() {
      this.createMenu = false;
      this.$emit('refreshCalendars');
    },

    addCalendar(id) {
      this.createMenu = false;
      this.$emit('addCalendar', id);
    },

    selectCalendars() {
      this.createMenu = false;
      this.$emit(
          'selectCalendars',
          this.calendars
              .filter((calendar) => calendar.selected)
              .map((calendar) => calendar['calendar_calendars.id']),
      );
    },

    filterCalendars() {
      if (this.search.length > 0) {
        return this.calendars.filter((element) => element['calendar_calendars.name'].search(this.search) >= 0);
      } else {
        return this.calendars;
      }
    },

    async deleteEvent(id) {
      this.loading = true;
      try {
        await axios.delete(`/calendar/calendars/${id}`);
        this.$emit('refreshCalendars');
      } catch (error) {
        console.log('error', error);
      } finally {
        this.loading = false;
      }
    },

    setAsDefault(id) {
      this.$emit('setAsDefault', id);
    },
  },
};
</script>

<style scoped>

.checkbox-label {
  position: relative;
  top: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

</style>
