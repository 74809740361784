var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"d-flex",attrs:{"color":"grey lighten-3","height":"54","tile":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{staticClass:"mr-4",attrs:{"color":"grey darken-1","outlined":""},on:{"click":_vm.today}},[_vm._v(" "+_vm._s(_vm.$t('system.today'))+" ")]),_c('v-btn',{attrs:{"icon":"","text":""},on:{"click":_vm.prev}},[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("fas fa-chevron-left")])],1),_c('v-btn',{attrs:{"icon":"","text":""},on:{"click":_vm.next}},[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("fas fa-chevron-right")])],1),(_vm.title)?_c('v-toolbar-title',{staticClass:"ml-4 mr-4",class:{'clickable-title': _vm.localType !== 'month'},on:{"click":function($event){return _vm.typeChange('week')}}},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c('v-btn',{attrs:{"disabled":_vm.loading,"loading":_vm.loading,"icon":"","text":""},on:{"click":_vm.refresh}},[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("fas fa-sync")])],1),_c('v-spacer'),_c('v-select',{staticClass:"fixed-size",staticStyle:{"max-width":"200px"},attrs:{"items":_vm.types,"label":_vm.$t('system.type'),"dense":"","hide-details":"","outlined":""},on:{"change":_vm.typeChange},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("system." + item)))+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("system." + item)))+" ")]}}]),model:{value:(_vm.localType),callback:function ($$v) {_vm.localType=$$v},expression:"localType"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"color":"grey darken-1","outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-3",attrs:{"size":"18"}},[_vm._v("fas fa-plus ")]),_vm._v(" "+_vm._s(_vm.$t('system.create_event'))+" ")],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('CreateEvent',{attrs:{"calendars":_vm.calendars,"default-calendar":_vm.defaultCalendar,"locale":_vm.locale},on:{"close":_vm.close}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }