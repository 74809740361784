<template>
  <v-sheet
      class="d-flex"
      color="grey lighten-3"
      height="54"
      tile
  >
    <v-toolbar
        flat
    >
      <v-btn
          class="mr-4"
          color="grey darken-1"
          outlined
          @click="today"
      >
        {{ $t('system.today') }}
      </v-btn>
      <v-btn
          icon
          text
          @click="prev"
      >
        <v-icon size="18">fas fa-chevron-left</v-icon>
      </v-btn>
      <v-btn
          icon
          text
          @click="next"
      >
        <v-icon size="18">fas fa-chevron-right</v-icon>
      </v-btn>
      <v-toolbar-title
          v-if="title"
          :class="{'clickable-title': localType !== 'month'}"
          class="ml-4 mr-4"
          @click="typeChange('week')"
      >
        {{ title }}
      </v-toolbar-title>
      <v-btn
          :disabled="loading"
          :loading="loading"
          icon
          text
          @click="refresh"
      >
        <v-icon size="18">fas fa-sync</v-icon>
      </v-btn>
      <v-spacer />
      <v-select
          v-model="localType"
          :items="types"
          :label="$t('system.type')"
          class="fixed-size"
          dense
          hide-details
          outlined
          style="max-width: 200px"
          @change="typeChange"
      >
        <template v-slot:selection="{ item }">
          {{ $t(`system.${item}`) }}
        </template>
        <template v-slot:item="{ item }">
          {{ $t(`system.${item}`) }}
        </template>
      </v-select>
      <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-bind="attrs"
              v-on="on"
              class="ml-4"
              color="grey darken-1"
              outlined
          >
            <v-icon
                class="mr-3"
                size="18"
            >fas fa-plus
            </v-icon>
            {{ $t('system.create_event') }}
          </v-btn>
        </template>
        <CreateEvent
            :calendars="calendars"
            :default-calendar="defaultCalendar"
            :locale="locale"
            @close="close"
        />
      </v-menu>
    </v-toolbar>
  </v-sheet>
</template>

<script>
import CreateEvent from '@/components/Calendar/CreateEvent';

export default {
  name: 'CalendarHeader',

  data() {
    return {
      localType: 'week',
      menu: false,
    };
  },

  components: {
    CreateEvent,
  },

  props: {
    title: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'month',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    types: {
      type: Array,
      default: () => [],
    },
    calendars: {
      type: Array,
      default: () => [],
    },
    defaultCalendar: {
      type: Number,
      default: null,
    },
    locale: {
      type: String,
      default: 'en',
    },
  },

  watch: {
    type: {
      handler() {
        this.localType = this.type;
      },
    },
  },

  methods: {

    prev() {
      this.$emit('prev');
    },

    next() {
      this.$emit('next');
    },

    today() {
      this.$emit('today');
    },

    typeChange(type) {
      this.$emit('typeChange', type);
    },

    refresh() {
      this.$emit('refresh');
    },

    close(action = null) {
      if (action && action.reload) {
        this.$emit('reload', action);
      }
      this.menu = false;
    },
  },
};
</script>

<style
    lang="scss"
    scoped
>

.clickable-title {
  cursor: pointer;
}

</style>
