<template>
  <v-card>
    <v-form
        ref="form"
        v-model="valid"
        class="pt-4 pr-4 pl-4"
    >
      <v-text-field
          v-model="formData.name"
          :label="$t('system.event_name')"
          :rules="required"
          dense
          outlined
          required
      />

      <v-select
          v-model="formData.calendar_id"
          :items="calendars"
          :label="$t('system.calendar')"
          class="mb-7"
          dense
          hide-details
          item-text="calendar_calendars.name"
          item-value="calendar_calendars.id"
          outlined
      >
        <template v-slot:selection="{ item }">
          <v-badge
              :color="item['calendar_calendars.color']"
              inline
              left
          />
          {{ item['calendar_calendars.name'] }}
        </template>
        <template v-slot:item="{ item }">
          <v-badge
              :color="item['calendar_calendars.color']"
              inline
              left
          />
          {{ item['calendar_calendars.name'] }}
        </template>
      </v-select>

      <v-datetime-picker
          v-model="formData.start"
          :clearText="$t('system.clear')"
          :label="$t('system.event_start')"
          :okText="$t('system.save')"
          :timeFormat="locale === 'pl' ? 'HH:mm' : 'hh:mm a'"
          :textFieldProps="{outlined: true, dense: true, 'append-icon': 'fas fa-calendar', rules: required}"
          :timePickerProps="{format: locale === 'pl' ? '24hr' : 'ampm'}"
          :datePickerProps="{'first-day-of-week': 1}"
          @input="datesValidator"
      >
        <template slot="dateIcon">
          <v-icon
              class="mr-2"
              size="17"
          >fas fa-calendar
          </v-icon>
          {{ $t('system.date') }}
        </template>
        <template slot="timeIcon">
          <v-icon
              class="mr-2"
              size="17"
          >fas fa-clock
          </v-icon>
          {{ $t('system.time') }}
        </template>
      </v-datetime-picker>

      <v-datetime-picker
          v-model="formData.end"
          :clearText="$t('system.clear')"
          :label="$t('system.event_end')"
          :okText="$t('system.save')"
          :timeFormat="locale === 'pl' ? 'HH:mm' : 'hh:mm a'"
          :textFieldProps="{outlined: true, dense: true, 'append-icon': 'fas fa-calendar', rules: required}"
          :timePickerProps="{min: `${new Date(formData.start || null).getHours()}:${new Date(formData.start || null).getMinutes()}`, format: locale === 'pl' ? '24hr' : 'ampm'}"
          :datePickerProps="{'first-day-of-week': 1, min: `${new Date(formData.start || null).toISOString()}`}"
      >
        <template slot="dateIcon">
          <v-icon
              class="mr-2"
              size="17"
          >fas fa-calendar
          </v-icon>
          {{ $t('system.date') }}
        </template>
        <template slot="timeIcon">
          <v-icon
              class="mr-2"
              size="17"
          >fas fa-clock
          </v-icon>
          {{ $t('system.time') }}
        </template>
      </v-datetime-picker>
    </v-form>

    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn
          :disabled="loading"
          :loading="loading"
          text
          @click="close"
      >
        {{ $t('system.cancel') }}
      </v-btn>
      <v-btn
          :disabled="loading"
          :loading="loading"
          color="primary"
          text
          @click="create"
      >
        {{ $t('system.create') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from 'axios';
import calendarDataPrepare from '@/mixin/calendarDataPrepare';

export default {
  name: 'CreateEvent',

  data() {
    return {
      formData: {},
      valid: false,
      required: [
        v => !!v || this.$t(''),
      ],
      loading: false,
    };
  },

  mixins: [calendarDataPrepare],

  props: {
    calendars: {
      type: Array,
      default: () => [],
    },
    defaultCalendar: {
      type: Number,
      default: null,
    },
    locale: {
      type: String,
      default: 'en',
    },
  },

  created() {
    this.assignDefaultCalendar();
  },

  watch: {
    defaultCalendar: {
      handler() {
        if (!this.formData.name) {
          this.assignDefaultCalendar();
        }
      },
    },
  },

  methods: {
    assignDefaultCalendar() {
      this.formData = this.prepareData(null, this.defaultCalendar);
    },

    datesValidator() {
      if (new Date(this.formData.start) > new Date(this.formData.end)) {
        this.formData.end = this.formData.start;
      }
    },

    close(action = null) {
      this.$emit('close', action);
    },

    async create() {
      this.$refs.form.validate();
      if (!this.valid) {
        return;
      }
      this.loading = true;
      try {
        const {data} = await axios.post(`/calendar/events`, this.parseEventObject(this.formData));
        this.formData.timeout = null;
        this.formData.id = data.data.base.id;
        await this.close({
          reload: true,
          type: 'create',
          data: this.formData,
        });
        this.assignDefaultCalendar();
      } catch (error) {
        console.log('error', error);
      } finally {
        this.loading = false;
      }
      this.$refs.form.resetValidation();
    },
  },
};
</script>
