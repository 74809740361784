<template>
  <v-menu
      v-model="localSelectedOpen"
      :activator="selectedElement"
      :close-on-click="!this.createEvent"
      :close-on-content-click="false"
      offset-x
  >
    <v-card
        color="grey lighten-4"
        flat
        min-width="350px"
    >
      <v-toolbar
          :color="selectedEvent.color"
          dark
      >
        <v-btn
            :disabled="loading"
            icon
            text
            @click="editEvent"
        >
          <v-icon size="17">fas fa-pencil-alt</v-icon>
        </v-btn>
        <v-toolbar-title>{{ selectedEvent.name }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
            :disabled="loading || createEvent || !selectedEvent.id"
            icon
            text
            @click="deleteEvent(selectedEvent.id)"
        >
          <v-icon size="17">fas fa-trash</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text v-if="!edit && !createEvent && selectedEvent.id">
        <div>
          <strong>{{ $t('system.calendar') }}: </strong>
          <span>{{
              calendars.find((element) => element['calendar_calendars.id'] === defaultCalendar)['calendar_calendars.name']
                }}</span>
        </div>
        <div>
          <strong>{{ $t('system.event_start_at') }}:</strong> <span>{{ formatDate(selectedEvent.start) }}</span>
        </div>
        <div>
          <strong>{{ $t('system.event_end_at') }}:</strong> <span>{{ formatDate(selectedEvent.end) }}</span>
        </div>
      </v-card-text>
      <v-form
          v-else
          ref="form"
          v-model="valid"
          class="pt-4 pr-4 pl-4"
          @keyup.native.enter="save"
      >
        <v-text-field
            v-model="formData.name"
            :label="$t('system.event_name')"
            :rules="required"
            dense
            outlined
            required
        />

        <v-select
            v-model="formData.calendar_id"
            :disabled="!!formData.id || !!selectedEvent.id"
            :items="calendars"
            :label="$t('system.calendar')"
            class="mb-7"
            dense
            hide-details
            item-text="calendar_calendars.name"
            item-value="calendar_calendars.id"
            outlined
        >
          <template v-slot:selection="{ item }">
            <v-badge
                :color="item['calendar_calendars.color']"
                inline
                left
            />
            {{ item['calendar_calendars.name'] }}
          </template>
          <template v-slot:item="{ item }">
            <v-badge
                :color="item['calendar_calendars.color']"
                inline
                left
            />
            {{ item['calendar_calendars.name'] }}
          </template>
        </v-select>

        <v-datetime-picker
            v-model="formData.start"
            :clearText="$t('system.clear')"
            :label="$t('system.event_start')"
            :okText="$t('system.save')"
            :timeFormat="locale === 'pl' ? 'HH:mm' : 'hh:mm a'"
            :textFieldProps="{outlined: true, dense: true, 'append-icon': 'fas fa-calendar', rules: required}"
            :timePickerProps="{format: locale === 'pl' ? '24hr' : 'ampm'}"
            :datePickerProps="{'first-day-of-week': 1}"
            @input="datesValidator"
        >
          <template slot="dateIcon">
            <v-icon
                class="mr-2"
                size="17"
            >fas fa-calendar
            </v-icon>
            {{ $t('system.date') }}
          </template>
          <template slot="timeIcon">
            <v-icon
                class="mr-2"
                size="17"
            >fas fa-clock
            </v-icon>
            {{ $t('system.time') }}
          </template>
        </v-datetime-picker>
        <v-datetime-picker
            v-model="formData.end"
            :clearText="$t('system.clear')"
            :label="$t('system.event_end')"
            :okText="$t('system.save')"
            :timeFormat="locale === 'pl' ? 'HH:mm' : 'hh:mm a'"
            :textFieldProps="{outlined: true, dense: true, 'append-icon': 'fas fa-calendar', rules: required}"
            :timePickerProps="{min: `${new Date(formData.start || null).getHours()}:${new Date(formData.start || null).getMinutes()}`, format: locale === 'pl' ? '24hr' : 'ampm'}"
            :datePickerProps="{'first-day-of-week': 1, min: `${new Date(formData.start || null).toISOString()}`}"
        >
          <template slot="dateIcon">
            <v-icon
                class="mr-2"
                size="17"
            >fas fa-calendar
            </v-icon>
            {{ $t('system.date') }}
          </template>
          <template slot="timeIcon">
            <v-icon
                class="mr-2"
                size="17"
            >fas fa-clock
            </v-icon>
            {{ $t('system.time') }}
          </template>
        </v-datetime-picker>
      </v-form>
      <v-card-actions
          v-if="!edit && !createEvent && selectedEvent.id"
          class="pt-0"
      >
        <v-btn
            text
            @click="close"
        >
          {{ $t('system.close') }}
        </v-btn>
      </v-card-actions>
      <v-card-actions
          v-else
          class="pt-0"
      >
        <v-btn
            text
            @click="cancelEdit"
        >
          {{ $t('system.cancel') }}
        </v-btn>
        <v-btn
            :disabled="loading"
            :loading="loading"
            color="primary"
            text
            @click="save"
        >
          {{ $t('system.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import axios from 'axios';
import calendarDataPrepare from '@/mixin/calendarDataPrepare';

export default {
  name: 'EventDetails',

  data() {
    return {
      loading: false,
      edit: false,
      localSelectedOpen: false,
      formData: {},
      valid: false,
      required: [
        v => !!v || this.$t(''),
      ],
    };
  },

  mixins: [calendarDataPrepare],

  props: {
    selectedElement: {
      default: null,
    },
    selectedEvent: {
      type: Object,
      default: () => {
      },
    },
    selectedOpen: {
      type: Boolean,
      default: false,
    },
    createEvent: {
      type: Boolean,
      default: false,
    },
    calendars: {
      type: Array,
      default: () => [],
    },
    defaultCalendar: {
      type: Number,
      default: null,
    },
    locale: {
      type: String,
      default: 'en',
    },
  },

  watch: {
    selectedOpen: {
      handler() {
        if (!this.localSelectedOpen && this.selectedOpen) {
          this.edit = false;
        }
        this.localSelectedOpen = this.selectedOpen;
        setTimeout(() => {
          if (this.createEvent) {
            this.editEvent();
          }
        });
      },
    },
  },

  methods: {
    close() {
      this.localSelectedOpen = false;
      this.$emit('close');
    },

    editEvent() {
      this.formData = this.prepareData(this.selectedEvent, this.defaultCalendar);
      this.edit = true;
    },

    datesValidator() {
      if (new Date(this.formData.start) > new Date(this.formData.end)) {
        this.formData.end = this.formData.start;
      }
    },

    async deleteEvent(id) {
      this.loading = true;
      try {
        this.formData.id = id;
        await axios.delete(`/calendar/events/${id}`);
        this.$emit('reload', {
          type: 'delete',
          data: this.formData,
        });
        this.$emit('close');
      } catch (error) {
        console.log('error', error);
      } finally {
        this.loading = false;
      }
    },

    cancelEdit() {
      this.edit = false;
      if (this.createEvent) {
        this.$emit('reload', {
          type: 'cancelCreate',
          data: this.formData,
        });
        this.$emit('close', false);
      }
    },

    save() {
      if (this.selectedEvent.id) {
        this.updateEvent();
      } else {
        this.createNewEvent();
      }
    },

    async updateEvent() {
      this.$refs.form.validate();
      if (!this.valid) {
        return;
      }
      this.loading = true;
      try {
        this.formData.id = this.selectedEvent.id;
        await axios.post(`/calendar/events/${this.formData.id}`, this.parseEventObject(this.formData));
        this.$emit('reload', {
          type: 'update',
          data: this.formData,
        });
        this.$emit('close');
        this.edit = false;
      } catch (error) {
        console.log('error', error);
      } finally {
        this.loading = false;
      }
      this.$refs.form.resetValidation();
    },

    async createNewEvent() {
      this.$refs.form.validate();
      if (!this.valid) {
        return;
      }
      this.loading = true;
      try {
        const {data} = await axios.post(`/calendar/events`, this.parseEventObject(this.formData));
        this.formData.id = data.data.base.id;
        this.$emit('reload', {
          type: 'createAlt',
          data: this.formData,
        });
        this.close();
      } catch (error) {
        console.log('error', error);
      } finally {
        this.loading = false;
      }
      this.$refs.form.resetValidation();
    },
  },
};
</script>
