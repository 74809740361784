var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"loading":_vm.loading && !_vm.formLoaded,"type":"table"}},[_c('div',{staticClass:"d-flex"},[_c('CalendarSidebar',{attrs:{"calendars":_vm.calendars,"selected-calendars":_vm.selectedCalendars,"default-calendar":_vm.defaultCalendar},on:{"refreshCalendars":function($event){_vm.fetchCalendars(); _vm.getEventsTrigger(true)},"addCalendar":_vm.fetchCalendars,"selectCalendars":_vm.changeSelectedCalendars,"setAsDefault":_vm.setAsDefault}}),_c('div',{staticStyle:{"width":"100%"}},[_c('CalendarHeader',{attrs:{"calendars":_vm.calendars,"default-calendar":_vm.defaultCalendar,"loading":_vm.loading,"title":_vm.$refs.calendar ? _vm.$refs.calendar.title : null,"type":_vm.type,"types":_vm.types,"locale":_vm.locale},on:{"next":function($event){return _vm.$refs.calendar.next()},"prev":function($event){return _vm.$refs.calendar.prev()},"reload":_vm.reload,"refresh":function($event){return _vm.getEventsTrigger(true)},"today":function($event){_vm.focus = ''},"typeChange":_vm.typeChange}}),_c('v-sheet',{staticClass:"pa-3 calendar-container"},[_c('v-calendar',{ref:"calendar",attrs:{"event-color":_vm.getEventColor,"event-overlap-threshold":60,"event-ripple":false,"events":_vm.getEvents(),"short-intervals":false,"type":_vm.type,"weekdays":_vm.weekday,"color":"primary","locale":_vm.locale},on:{"change":function($event){return _vm.getEventsTrigger()},"click:date":_vm.viewDay,"click:event":_vm.showEvent,"click:more":_vm.viewDay,"mousedown:event":_vm.startDrag,"mousedown:time":_vm.startTime,"mousemove:time":_vm.mouseMove},nativeOn:{"mouseup":function($event){return _vm.endDrag($event)},"mouseleave":function($event){return _vm.cancelDrag($event)}},scopedSlots:_vm._u([{key:"event",fn:function(ref){
var event = ref.event;
var timed = ref.timed;
var eventSummary = ref.eventSummary;
return [_c('div',{staticClass:"v-event-draggable",domProps:{"innerHTML":_vm._s(eventSummary())}}),(timed)?_c('div',{staticClass:"v-event-drag-bottom",on:{"mousedown":function($event){$event.stopPropagation();return _vm.extendBottom(event)}}}):_vm._e()]}},{key:"day-body",fn:function(ref){
var date = ref.date;
var week = ref.week;
return [_c('div',{staticClass:"v-current-time",class:{ first: date === week[0].date },style:({ top: _vm.nowY })})]}}]),model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}})],1)],1)],1)]),_c('EventDetails',{attrs:{"calendars":_vm.calendars,"create-event":_vm.selectedCreate,"default-calendar":_vm.defaultCalendar,"selected-element":_vm.selectedElement,"selected-event":_vm.selectedEvent,"selected-open":_vm.selectedOpen,"locale":_vm.locale},on:{"close":function($event){_vm.selectedOpen = false; _vm.selectedCreate = false},"reload":_vm.reload}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }