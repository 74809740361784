<template>
  <v-card>
    <v-form
        ref="createForm"
        v-model="valid"
        class="pt-4 pr-4 pl-4"
    >
      <v-text-field
          v-model="formData.name"
          :label="$t('system.name')"
          :rules="required"
          dense
          outlined
      />
      <v-text-field
          v-model="formData.description"
          :label="$t('system.description')"
          dense
          outlined
      />
      <v-color-picker
          v-model="formData.color"
          class="calendar-color-picker"
          dot-size="30"
          hide-canvas
          hide-inputs
          hide-mode-switch
          hide-sliders
          mode="hexa"
          show-swatches
          swatches-max-height="140"
      />
    </v-form>

    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn
          text
          @click="close()"
      >
        {{ $t('system.cancel') }}
      </v-btn>
      <v-btn
          :disabled="loading"
          :loading="loading"
          color="primary"
          text
          @click="save()"
      >
        {{ $t('system.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from 'axios';

export default {
  name: 'CreateEditCalendar',

  data() {
    return {
      loading: false,
      valid: true,
      formData: this.prepareData({}),
      required: [
        v => !!v || this.$t('system.required'),
      ],
    };
  },

  props: {
    item: {
      type: Object,
      default: () => {
      },
    },
  },

  watch: {
    item: {
      handler() {
        this.formData = this.prepareData(this.item);
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    prepareData(item = {}) {
      return {
        name: item['calendar_calendars.name'] || '',
        description: item['calendar_calendars.description'] || '',
        color: item['calendar_calendars.color'] || '#3F51B5FF',
      };
    },

    close() {
      this.$emit('close');
    },

    save() {
      this.$refs.createForm.validate();
      if (!this.valid) {
        return;
      }
      if (this.item && this.item['calendar_calendars.id']) {
        this.editCalendar();
      } else {
        this.createCalendar();
      }
    },

    async createCalendar() {
      this.loading = true;
      try {
        this.formData.form_id = 9;
        const {data} = await axios.post(`/calendar/calendars`, this.formData);
        this.$emit('create', data.id);
        this.formData = this.prepareData();
        this.close();
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
      this.$refs.createForm.resetValidation();
    },

    async editCalendar() {
      this.loading = true;
      try {
        await axios.post(`/calendar/calendars/${this.item['calendar_calendars.id']}`, this.formData);
        this.$emit('edit');
        this.formData = this.prepareData();
      } catch (error) {
        console.log('error', error);
      } finally {
        this.loading = false;
      }
      this.$refs.createForm.resetValidation();
    },
  },
};
</script>

<style lang="scss">

.calendar-color-picker {
  .v-color-picker__swatch {
    margin-bottom: 4px !important;

    .v-color-picker__color {
      height: 22px !important;
      max-height: 22px !important;

      &:not(:first-child) {
        display: none !important;
      }
    }
  }
}

</style>
