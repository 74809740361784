import moment from 'moment';

export default {
  methods: {
    parseEvents(data) {
      const returnData = [];
      data.forEach((element) => {
        returnData.push(this.eventConstructor(element, 'calendar_events'));
      });
      return returnData;
    },

    eventConstructor(el, pref) {
      return {
        id: el[`${pref}.id`] ? el[`${pref}.id`] : null,
        name: el[`${pref}.name`] ? el[`${pref}.name`] : '',
        start: el[`${pref}.start`] ? new Date(el[`${pref}.start`]).getTime() : '',
        end: el[`${pref}.end`] ? new Date(el[`${pref}.end`]).getTime() - this.checkIsMidnight(new Date(el[`${pref}.end`])) : '',
        color: el[`${pref}.calendar`] && el[`${pref}.calendar`].color ? el[`${pref}.calendar`].color : '',
        calendar_id: el[`${pref}.calendar_id`] ? el[`${pref}.calendar_id`] : null,
        timed: true,
        timeout: null,
      };
    },

    checkIsMidnight(dateToCheck) {
      if (dateToCheck && `${new Date(dateToCheck).getHours()}:${new Date(dateToCheck).getMinutes()}` === '0:0') {
        return 1000;
      }
      return 0;
    },

    getEventsBodyConstructor(focus) {
      const dateRange = this.setDateRanges(focus);

      return {
        form_id: 8,
        column: 'calendar_events.uid',
        direction: 'asc',
        from: dateRange.from.slice(0, 10),
        to: dateRange.to.slice(0, 10),
        // filters: {
        //   calendar_id: this.calendars
        //     .filter((element) => element.selected)
        //     .map((element) => element['calendar_calendars.id']),
        // },
      };
    },

    setDateRanges(focus) {
      let dateRange = {
        from: '',
        to: '',
      };
      if (focus && focus.length > 0) {
        dateRange.from = new Date(Date.parse(focus));
        dateRange.to = new Date(Date.parse(focus));
      } else {
        dateRange.from = new Date();
        dateRange.to = new Date();
      }

      dateRange.from.setDate(1);
      dateRange.from.setMonth(dateRange.from.getMonth() - 3);
      dateRange.from = dateRange.from.toISOString();

      dateRange.to.setMonth(dateRange.to.getMonth() + 4);
      dateRange.to.setDate(0);
      dateRange.to = dateRange.to.toISOString();

      return dateRange;
    },

    prepareData(data = null, defaultCalendar = null) {
      let now = new Date();
      let oneHour = new Date();
      oneHour.setHours(oneHour.getHours() + 1);

      return {
        name: data && data.name ? data.name : '',
        start: data && data.start ? new Date(data.start) : now,
        end: data && data.end ? new Date(data.end) : oneHour,
        form_id: 8,
        repeat_at: '',
        calendar_id: data && data.calendar_id ? data.calendar_id : defaultCalendar,
      };
    },

    parseEventObject(event) {
      return {
        ...event,
        start: this.formatISODate(event.start),
        end: this.formatISODate(event.end),
      };
    },

    formatDate(date, standard = false) {
      const locale = localStorage.getItem('langLocal') || 'en';
      const format = {
        _12h: 'YYYY-MM-DD, hh:mm a',
        _24h: 'YYYY-MM-DD, HH:mm',
        standard: 'YYYY-MM-DD HH:mm',
      };
      if (standard) {
        return moment(date).format(format.standard);
      }
      return locale === 'pl' ? moment(date).format(format._24h) : moment(date).format(format._12h);
    },

    formatISODate(date) {
      return new Date(date).toISOString();
    },
  },
};
